/*
=============== 
Fonts
===============
*/
@import url("https://fonts.googleapis.com/css?family=Lato:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Catamaran:400,700|Grand+Hotel");


/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(184, 91%, 17%);
  --clr-primary-2: hsl(185, 84%, 25%);
  --clr-primary-3: hsl(185, 81%, 29%);
  --clr-primary-4: hsl(184, 77%, 34%);
  /* primary/main color */
  --clr-primary-5: hsl(185, 62%, 45%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(185, 57%, 50%);
  --clr-primary-7: hsl(184, 65%, 59%);
  --clr-primary-8: hsl(184, 80%, 74%);
  --clr-primary-9: hsl(185, 94%, 87%);
  --clr-primary-10: hsl(186, 100%, 94%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);



  --clr-primary: hsl(185, 62%, 45%);
  --clr-primary-light: #beed8c;
  --clr-grey-1: #102a42;
  --clr-grey-5: #617d98;
  --clr-grey-10: #f1f5f8;
  --clr-white: #fff;



  --clr-white: #fff;

  --heading-color: #444;
  --text-color: #999;

  /* notification colours */
  --clr-highlight: #d13267;
  --clr-success: #09792a;

  /* status colours */
  --clr-status-pending: #280880;
  --clr-status-inprogress: #e7bf0a;
  --clr-status-completed: #09792a;
  --clr-status-cancelled: #f10b0b;

  /* --ff-primary: "Lato", sans-serif; */
  --ff-primary: "Catamaran", sans-serif;
  --ff-secondary: "Grand Hotel", cursive;
  --transition: all 0.3s linear;
  --spacing: 0.20rem;
  --radius: 0.5rem;

  --border-light: 1px solid var(--clr-grey-9);
  --border-dark: 1px solid var(--clr-grey-4);
  --border-highlight: 1px solid var(--heading-color);

  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --box-shadow-hover: 0 5px 15px rgba(0, 0, 0, 0.4);

  --margin-form-lbl: 0.2rem;
  --margin-form-btn: 0.5rem;
}

/*
=============== 
Global Styles
===============
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--ff-primary);
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

/* img {
  width: 100%;
  display: block;
} */

img:not(.nav-logo) {
  width: 100%;
  display: block;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.3;
  margin-bottom: 0.50rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}

label {
  letter-spacing: var(--spacing);
  display: block;
  margin: 1.25rem auto;
}

label span {
  display: block;
}

input,
textarea {
  padding: 8px 6px;
  width: 100%;
  border: var(--border-light);
  border-radius: var(--radius);
  color: var(--clr-grey-4);
  font-size: 1em;
}

textarea {
  min-height: 160px;
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1rem;
  }

  body {
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

/*  global classes */
.btn {
  /* colors */
  background: var(--clr-primary-5);
  color: var(--clr-white);

  /* padding and margin */
  display: inline-block;
  padding: 0.375rem 0.75rem;

  /* border */
  border: 2px solid transparent;
  border-radius: var(--radius);

  /* text Properties */
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: var(--spacing);

  /* other */
  -webkit-transition: var(--transition);
  transition: var(--transition);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.btn:hover {
  color: var(--clr-primary-1);
  background: var(--clr-primary-8);
}

.btn-danger {
  /* colors */
  background: var(--clr-highlight);
  color: var(--clr-white);
}

.btn-danger:hover {
  color: var(--clr-highlight);
  background: transparent;
  border: 2px solid var(--clr-highlight);
}

.btn-success {
  /* colors */
  background: var(--clr-success);
  color: var(--clr-white);
}

.btn-success:hover {
  color: var(--clr-highlight);
  background: transparent;
  border: 2px solid var(--clr-success);
}


.label-container {
  display: flex;
}

.label-container p {
  text-transform: capitalize;
  margin-bottom: var(--margin-form-lbl);
}

/* content divider */
.content-divider {
  height: 0.5rem;
  background: linear-gradient(to left,
      var(--clr-primary-3),
      var(--clr-primary-9),
      var(--clr-primary-3));
}

/*
=============== 
Highlights
===============
*/

.is-required {
  color: red;
  padding-left: 2px;
}

.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

.form-icon {
    width: 20px;
}

/*
=============== 
Status
===============
*/

.status {
  display: block;
  max-width: 100px;
  padding: 5px;
  font-size: 0.8rem;
  text-align: center;
  color: #fff;
  border-radius: var(--radius);
}

.pending {
  background: var(--status-pending);
}

.inprogress {
  background: var(--status-inprogress);
}

.completed {
  background: var(--status-completed);
}

/*
=============== 
Pages and Sections
===============
*/

.page-container {
  min-height: 100vh;
  max-width: 1170px;
  margin: 120px auto;
}

/* page */
.page-title {
  font-size: 1em;
  color: var(--clr-primary-5);
  display: inline-block;
}

/* 
/* section */

.clearfix::after,
.clearfix::before {
  clear: both;
  content: "";
  display: table;
}

.section {
  padding: 2rem 0;
}

.section-title span {
  color: var(--clr-primary-5)
}

.section-title {
  margin-bottom: 1rem;
}

.section-title h2 {
  text-transform: capitalize;
}

.section-title h3 {
  font-family: var(--ff-secondary);
  color: var(--clr-primary);
}

.section-center {
  padding: 2rem 0;
  width: 85vw;
  margin: 0 auto;
  max-width: 1170px;
}

@media screen and (min-width:992px) {
  .section-center {
    width: 90vw;
    padding: 2rem 1rem;
  }
}

/*
=============== 
Animation
===============
*/
@keyframes bounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes show {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    transform: scale(1.5);
    opacity: 0.5
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideFromRight {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }

  50% {
    transform: translateX(-200px);
    opacity: 0.5;
  }

  75% {
    transform: translateX(50px);
    opacity: 0.75;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }

  50% {
    transform: translateX(200px);
    opacity: 0.5;
  }

  75% {
    transform: translateX(-50px);
    opacity: 0.75;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}