:root {
 --clr-primary-background: var(background: var(--clr-grey-10));
}


/*
=============== 
Home
===============
*/
.home {
 background: var(--clr-primary-background);
}


/*
=============== 
About
===============
*/

.about-center {
 display: block;
 width: 95vw;
 background: var(--clr-white);
}

.about-img,
.about-info {
 padding: 2rem 0;
}

.about {
 padding: 1.5rem 1rem;
 text-align: left;
 transition: var(--transition);
}

.about-picture-container {
 border: 0.3rem solid var(--clr-primary);
 max-width: 95vw;
 /* extra stuff */
 overflow: hidden;
}

.about-picture {
 transition: var(--transition);
}

.about-picture-container:hover .about-picture {
 opacity: 0.5;
 transform: scale(1.2);
}

@media screen and (min-width:576px) {
 .about {
  float: left;
  width: 50%;
 }
}


/*
=============== 
Contact
===============
*/

.contact {
 background: var(--clr-primary-5);
}

.contact-title {
 color: var(--clr-white);
}

.contact-title p {
 color: var(--clr-primary-8);
}

.contact-form {
 display: -webkit-box;
 display: flex;
 max-width: 28rem;
}

.contact-info {
 margin-bottom: 0;
 letter-spacing: 1px;
 line-height: 1.3;
}


.contact-info .address {
 line-height: 1;
 margin:0;
 padding:0;
}
.contact-info .address p{
 margin-bottom: 2px;
}

.contact-details {
 margin-top:20px;
}
.icon-container {
 margin: 0;
 display: flex;
 align-items: center;
 font-size: 1.1rem;
 letter-spacing: var(--spacing);
}

.icon {
 color: var(--clr-primary-5);
 font-size: 1.2rem;
 align-self: center;
 margin-right: 10px;
}

.form-control {
 -webkit-box-flex: 1;
 flex: 1 1 auto;
 color: var(--clr-grey-5)
}

.form-control,
.btn-submit {
 padding: 0.375rem 0.75rem;
 line-height: 1.5;
 font-weight: 300;
 letter-spacing: var(--spacing);
 background: var(--clr-white);
 text-transform: capitalize;
 border: transparent
}

.form-control::-webkit-input-placeholder {
 color: inherit;
}

.form-control::-moz-placeholder {
 color: inherit;
}

.form-control:-ms-input-placeholder {
 color: inherit;
}

.form-control::-ms-input-placeholder {
 color: inherit;
}

.form-control::placeholder {
 color: inherit;
}

.admin-page-container {
 display: flex;
 margin-top: 0;
}
.dashboard-page {
 display: flex;
 margin-top: 0;
}

.btn-submit {
 font-family: var(--ff-primary);
 margin-left: 0.5rem;
 background: var(--clr-primary-8);
 color: var(--clr-primary-1);
 cursor: pointer;
 -webkit-transition: var(--transition);
 transition: var(--transition);
}

.btn-submit:hover {
 background: var(--clr-primary-1);
 color: var(--clr-primary-9)
}

@media screen and (min-width:992px) {
 .contact-center {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
 }

 .contact-form {
  align-self: flex-start;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  max-width: 100%;
  padding-left: 2rem;
 }

}

/*
=============== 
Featured Tours
===============
*/
.tour-card {
 -webkit-transition: var(--transition);
 transition: var(--transition);
 background: var(--clr-grey-10);
 box-shadow: var(--light-shadow);
 margin-bottom: 2rem;

}

.tour-card:hover {
 box-shadow: var(--dark-shadow);
 -webkit-transform: scale(1.02);
 transform: scale(1.02);
}

.tour-img-container {
 position: relative;
}

.tour-img {
 height: 15rem;
 -o-object-fit: cover;
 object-fit: cover;
}

.tour-date {
 position: absolute;
 right: 0;
 bottom: 0;
 background: var(--clr-primary-4);
 padding: 0.25rem 0.5rem;
 margin-bottom: 0;
 text-transform: uppercase;
 color: var(--clr-white);
 font-size: 1.1rem;
}

.tour-info {
 padding: 1.25rem 1.5rem;
}

.tour-title {
 display: -webkit-box;
 display: flex;
 -webkit-box-pack: justify;
 justify-content: space-between;
 flex-wrap: wrap;
 -webkit-box-align: center;
 align-items: center;
}

.tour-footer {
 display: -webkit-box;
 display: flex;
 -webkit-box-pack: justify;
 justify-content: space-between;
 flex-wrap: wrap;
 -webkit-box-align: center;
 align-items: center;
}

.tour-footer p {
 line-height: 0;
 margin-bottom: 0;
 text-transform: capitalize;
 color: var(--clr-primary-5);
}

.tour-btn {
 text-align: center;
}

@media screen and (min-width:768px) {
 .featured-center {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
 }

 .tour-card {
  -webkit-box-flex: 0;
  flex: 0 0 calc(50% - 2rem);
 }
}

@media screen and (min-width:1170px) {
 .tour-card {
  -webkit-box-flex: 0;
  flex: 0 0 calc(33.33% - 2rem);
 }
}

/*
=============== 
Gallery
===============
*/

.gallery-img-container {
 position: relative;
 background-color: var(--clr-primary-5);
}

.gallery-icon {
 position: absolute;
 top: 50%;
 left: 50%;
 font-size: 2rem;
 -webkit-transform: translate((-100%, -100%));
 transform: translate((-100%, -100%));
 color: var(--clr-white);
 opacity: 0;
 -webkit-transition: var(--transition);
 transition: var(--transition);
}

.gallery-img {
 height: 17rem;
 -o-object-fit: cover;
 object-fit: cover;
 -webkit-transition: var(--transition);
 transition: var(--transition);
}

.gallery-img-container:hover .gallery-img {
 opacity: 0.5;
}

.gallery-img-container:hover .gallery-icon {
 opacity: 1;
}

@media screen and (min-width:768px) {
 .gallery-center {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
 }

 .gallery-img-container {
  -webkit-box-flex: 0;
  flex: 0 0 50%;
 }
}

@media screen and (min-width:992px) {
 .gallery-img-container {
  -webkit-box-flex: 0;
  flex: 0 0 25%;
 }
}