/*
=============== 
Hero
===============
*/
.hero {
  min-height: 50vh;
  background: var(--clr-primary-4);
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;

}

.hero-banner {
  text-align: center;
  color: var(--clr-white);
  max-width: 85vw;
  /* padding: 0 3rem; */
}

.hero-banner h1 {
  text-transform: uppercase;
}

.hero-banner p {
  margin: 1rem auto;
  color: var(--clr-white);
  letter-spacing: var(--spacing);
}

.hero-btn {
  padding: 0.9rem 1.6rem;
  font-size: 1.25rem;
  background: var(--clr-white);
  color: var(--clr-primary-5)
}

.hero-btn:hover {
  background: transparent;
  color: var(--clr-white);
  border-color: var(--clr-white);
}

@media screen and (min-width: 530px) {
  .hero {
    background: -webkit-gradient(linear, left top, left bottom, from(rgb(44, 174, 186, 0.7)), to(rgba(0, 0, 0, 0.7))),
      url('../../assets/images/main.jpeg') center/cover no-repeat;
    background: linear-gradient(rgb(44, 174, 186, 0.7), rgba(0, 0, 0, 0.7)),
      url('../../assets/images/main.jpeg') center/cover no-repeat;
  }

  .hero-banner {
    padding: 0;
  }

  .hero-banner p {
    max-width: 35rem;
  }
}