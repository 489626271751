/*
=============== 
Footer
===============
*/
footer {
 background: var(--clr-grey-1);
 /* position: fixed; */
 /* position:absolute; */
 bottom: 0;
 left: 0;
 width: 100%;
 text-align: center;
 padding: 1rem;
 box-shadow: var(--dark-shadow);
 z-index: 2;
}

.footer-header{

}
.footer-footer{


}

.footer-links {
 display: -webkit-box;
 display: flex;
 -webkit-box-pack: center;
 justify-content:center;
 margin-bottom: 1.5rem;
 flex-wrap: wrap;
}

.footer-link {
 color: var(--clr-white);
 text-transform: capitalize;
 font-size: 1rem;
 margin-right: 1rem;
 letter-spacing: var(--spacing);
 -webkit-transition: var(--transition);
 transition: var(--transition);
}

.footer-link:hover {
 color: var(--clr-primary-5);
}

.footer-icons {
 display: -webkit-box;
 display: flex;
 -webkit-box-pack: center;
 justify-content: center;
 margin-bottom: 1rem;
}

.footer-icon {
 font-size: 2rem;
 margin-right: 1rem;
 color: var(--clr-primary-8);
}

.footer-icon:hover {
 color: var(--clr-white);
}

.copyright {
 text-transform: capitalize;
 color: var(--clr-white);
}

.rights {
 text-transform: capitalize;
 margin-left: 1rem;
}