/*
=============== 
Skills
===============
*/

.skills {
 background: var(--clr-grey-10);
 max-height: 40rem;
}

.skill {
 padding: 15px 0;
 text-align: center;
 transition: var(--transition);
}

.skill-icon {
 font-size: 2.5rem;
 margin-bottom: 1, .25rem;
 transition: var(--transition);
 display: inline-block;
 color: var(--clr-primary);
}

.skill-text {
 color: var(--clr-grey-5);
 font-size: 1rem;
 max-width: 90%;
 margin: 0 auto;
}

.skill:hover {
 background: white;
 box-shadow: 0 2px var(--clr-primary);
}

.skill:hover .skill-icon {
 transform: translateY(-5px);
}

@media screen and (min-width:530px) {
 .skill {
  float: left;
  width: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
 }
}

@media screen and (min-width:1200px) {
 .skill {
  float: left;
  width: 25%;
 }

 .skill-text {
  color: var(--clr-grey-5);
  font-size: 1rem;
  max-width: 70%;
  margin: 0 auto;
 }
}