.sidebar {
  width: 250px;
  min-width: 250px;
  background: var(--clr-grey-5);
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  color: #fff;
}


.sidebar nav {
  background: var(--clr-grey-5);
  box-shadow: none;
}
.sidebar nav a:hover {
  background: var(--clr-primary-5);
  box-shadow: none;
}

.sidebar-content {
  position: fixed;
  width: inherit;
}

.sidebar .user {
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  padding-top: 4rem;
  padding-bottom: 1rem;

  /* padding: 4rem 30px; */
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.sidebar-icon {
  font-size: 1.4rem;
  margin-right:10px;
}

.sidebar p {
  color: var(--clr-white);
}

.sidebar .links {
  margin-top: 20px;
  margin-left: 20px;
}

.sidebar .links li {
  margin-top: 10px;
}

.sidebar .links a {
  display: flex;
  padding: 10px;
  text-decoration: none;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  font-size: 1rem;
}

.sidebar .links img {
  width: 30px;
  margin-right: 10px;
  filter: invert(100%);
}

.sidebar .links a.active {
  color: var(--clr-primary-8);
  background: var(--bg-color);
  border-radius: 20px 0 0 20px;
}

.sidebar .links .active img {
  filter: invert(40%);
  color: var(--clr-primary-8);
}