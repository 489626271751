
.form-control {
 border: 1 solid grey;
}

.formInput {
 margin-top: 5px;
 margin-bottom: 5px;
 border:1px solid rgba(0,0,0,0.1);
 letter-spacing: 1px;
 text-transform: none;
}

.errorMessage {
 color:red;
}