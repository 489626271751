.service-container {
 display: flex;
 margin-top: 30px;
}

.service {
 margin-top: 50px;
}

.service-header {
 margin-left: 2rem;
}

.service-header h2 {
 color: var(--clr-primary-5);
}

.service-content {
 margin-left: 2rem;
}

.service-filter {
 margin: 10px auto;
 font-size: 1.2rem;
}

.service-filter nav {
 display: flex;
 background-color: #fff;
 max-height: 25px;
}

.service-filter p {
 margin-right: 20px;
}

.service-filter button {
 background: transparent;
 border: 0;
 color: var(--text-color);
 cursor: pointer;
 border-right: 2px solid #e4e4e4;
 font-size: inherit;
 padding: 0 5px;

}

.service-filter button:last-child {
 border: 0;
}

.service-filter button.active {
 color: var(--primary-color);
}