.service-list {
 margin-top: 20px;
 display: grid;
 grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
 grid-gap: 30px;
}

.service-list a {
 background-color: #fff;
 padding: 16px;
 border-radius: 6px;
 box-shadow: var(--light-shadow);
 text-decoration: none;
 color: inherit;
}

.service-list h4 {
 font-size: 0.9em;
 color: var (--heading-color);
}

.service-list p {
 color: var(--text-color);
 font-size: 0.9em;
}

.service-list .assigned-to {
 margin-top: 20px;
 padding-top: 10px;
 border-top: 1px solid #eee;
}

.service-list ul {
 margin: 10px 0;
 display: flex;

}

.service-list li {
 margin-right: 10px;
}

.service-list .avatar {
 width: 30px;
 height: 30px;
}