/*
=============== 
Services
===============
*/

.services {
 background: var(--clr-grey-10);
}

.services-title {
 margin-top: 4rem;
 margin-bottom: -2rem;
 text-align: center;
}

.service-card {

 margin: 2rem 0;
 background: var(--clr-grey-10);
 border-radius: var(--radius);
 box-shadow: var(--box-shadow);
 transition: var(--transition);
}

.service-card:hover {
 transform: scale(1.02);
 box-shadow: var(--box-shadow-hover);
}

.service-img {
 height: 17rem;
 object-fit: cover;
 border-radius: var(--radius) var(--radius) 0 0;
}

.service-info {
 text-align: center;
 padding: 3rem 1rem 2.5rem 1rem;
}

.service-info p {
 max-width: 20rem;
 color: var(--clr-grey-5);
 margin: 0 auto;
}

.service-btn {
 font-size: 0.75rem;
 text-transform: capitalize;
 padding: 0.375rem 0.5rem;
 border-radius: var(--radius);
 font-weight: 400;
 margin-top: 1.25rem;

}

@media screen and (min-width:576px) {
 .service-card {
  float: left;
  width: 45%;
  margin-right: 5%;
 }
}

@media screen and (min-width:992px) {
 .service-card {
  float: left;
  width: 30%;
  margin-right: 3.33%;
 }
}

@media screen and (min-width:1100px) {
 .service-card {
  float: left;
  width: 23%;
  margin-right: 2%;
 }
}

.service-img-container {
 position: relative;

}

.service-icon {
 position: absolute;
 left: 50%;
 bottom: 0;
 font-size: 2rem;
 color: var(--ff-primary);
 background: var(--clr-primary-light);
 padding: 0.25rem 0.6rem;
 border-radius: 50%;
 transform: translate(-50%, 50%);
 border: 0.375rem solid var(--clr-grey-10);
}