.auth-form {
 max-width: 400px;
 margin: 120px auto;
 padding: 40px;
 border: 1px solid #ddd;
 box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
 background: #fff;
}

.auth-btn {
 margin-top: 1rem;
}

.register-container {
 margin-top: 1rem;
 font-size: 0.9rem;
 display: flex;
}

.register-container p {
 margin-right: 10px;
}

.register-link {
 display: block;
 text-transform: capitalize;
 letter-spacing: var(--spacing);
 -webkit-transition: var(--transition);
 transition: var(--transition);
 color: var(--clr-grey-1);
}

.register-link :hover {
 color: var(--clr-primary-1);
 background: var(--clr-primary-8);
 padding-left: 2.5rem;
}