.avatar {
 display: inline-block;
 width: 60px;
 height:60px;
 border-radius: 50%;
 border: solid 3px rgb(230, 229, 229);
 overflow: hidden;
}

.avatar img{
 width:100%;
 height:100%;
}